import React from "react";
import Layout from "../layout";
import { layoutType } from "../../utils/constants";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <div
      className="d-flex flex-column flex-root"
      id="kt_app_root"
      style={{ height: "100vh" }}
    >
      <div
        className="d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep"
        id="kt_create_account_stepper"
      >
        <Layout type={layoutType.HOME} />

        <div className="d-flex flex-column flex-lg-row-fluid py-10">
          <div className="d-flex flex-center flex-column flex-column-fluid">
            <div className="w-lg-650px w-xl-700px p-10 p-lg-15">
              <h1>404</h1>
              <h3>Page Not Found</h3>
              <div className="mr-2">
                <button
                  type="button"
                  className={`btn btn-lg btn-light-primary me-3`}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <span className="ms-3">{"Back to home"}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
