import React from "react";
import Footer from "./footer";
import { useNavigate } from "react-router-dom";
import successGif from "../../assets/img/sucess.gif";
import alert from "../../assets/img/alert.svg";

const Success = ({ onPrev, onNext, errorText }) => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="w-100">
        <div className="pb-4 pb-lg-4">
          <div
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <img
              style={{
                height: "200px",
                marginBottom: 20,
                width: "200px",
              }}
              src={successGif}
            />
          </div>
          <h2 className="fw-bold text-dark">
            {`Yay! Your referral details have been ${
              errorText ? "saved" : "submitted"
            } successfully. `}
          </h2>
          {errorText && (
            <div
              class={`notice d-flex bg-light-warning rounded border-warning border border-dashed p-6`}
            >
              <div class="d-flex flex-stack flex-grow-1 ">
                <img
                  className="ki-duotone ki-information fs-2tx text-warning me-4"
                  src={alert}
                />
                <div class=" fw-semibold">
                  {/* <h4 class="text-gray-900 fw-bold">We need your attention!</h4> */}
                  <div class="fs-6 text-gray-700 ">{errorText}</div>
                </div>
              </div>
            </div>
          )}
          {/* <div className="text-muted fw-semibold fs-6">If you need more info, please
						<a href="../../demo1/dist/authentication/layouts/corporate/sign-in.html" className="link-primary fw-bold"> Sign In</a>.</div> */}
        </div>

        {/* <div className="mb-0">

					<div className="fs-6 text-gray-600 mb-5">Writing headlines for blog posts is as much an art as it is a science and probably warrants its own post, but for all advise is with what works for your great & amazing audience.</div>

					<div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">

						<span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor" />
								<rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor" />
								<rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor" />
							</svg>
						</span>

						<div className="d-flex flex-stack flex-grow-1">
							<div className="fw-semibold">
								<h4 className="text-gray-900 fw-bold">Check Referral Status!</h4>
								<div className="fs-6 text-gray-700">To check the status of your referrals,
									<span 
										className="link-primary fw-bold cursor-pointer"
										onClick={() => navigate("/referral/status")}
									> Click here</span>.
								</div>
							</div>
						</div>
					</div>
				</div> */}

        <div className="fw-semibold mb-4">
          {/* <h4 className="text-gray-900 fw-bold">Check Referral Status!</h4> */}
          <div className="fs-6 text-gray-700">
            To track the status of your earnings and referrals,
            <span
              className="link-primary fw-bold cursor-pointer"
              onClick={() => navigate("/referral/status")}
            >
              {" "}
              Click here
            </span>
            .
          </div>
        </div>
      </div>

      <Footer
        prevText="Return to home"
        showNext={true}
        onPrev={onPrev}
        nextText="Refer more"
        showPrev={true}
        onNext={onNext}
      />
    </div>
  );
};

export default Success;
