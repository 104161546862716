import React, { useState } from "react";
import Footer from "./footer";
import Error from "../common/error";
import ErrorMessage from "../common/errorMessage";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";

const BankAccount = ({ onPrev, onNext, token }) => {
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState({});
  const [errorText, setErrorText] = useState("");

  const [bankName, setBankName] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [panNumber, setPanCard] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [retypeAccountNumber, setReTypeAccountNumber] = useState("");
  const [ifsc, setIfsc] = useState("");

  const canSubmit = () => {
    let temp = {};

    if (!(bankName && bankName.trim())) {
      temp.bankName = "Enter Bank name";
    }

    if (!accountNumber) {
      temp.accountNumber = "Enter the bank account number";
    }

    if (!panNumber) {
      temp.panCard = "Enter the pan card number";
    }

    const panNumberRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    if (!panNumberRegex.test(panNumber)) {
      temp.panCard = "Enter valid pan card number";
    }

    if (!accountHolderName) {
      temp.accountHolderName = "Enter the account holder name";
    }

    if (!retypeAccountNumber) {
      temp.retypeAccountNumber = "Re-type the account number";
    }

    if (retypeAccountNumber != accountNumber) {
      temp.retypeAccountNumber = "Account number does not match";
    }
    const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    if (!(ifsc && ifsc.trim())) {
      temp.ifsc = "Enter IFSC code";
    }
    if (!ifscRegex.test(ifsc)) {
      temp.ifsc = "Enter valid IFSC code";
    }

    setError(temp);
    console.log(temp);
    return Object.keys(temp).length > 0 ? false : true;
  };

  const saveBankDetails = async () => {
    if (!canSubmit()) {
      return;
    }

    let payload = {
      bankName,
      accountNumber,
      ifsc,
      panNumber,
      accountHolderName,
    };

    setLoading(true);

    const data = await Api.doFetch(
      "POST",
      payload,
      ApiUrl.SAVE_BANK_DETAILS,
      token
    );

    if (data?.status) {
      window.scrollTo(0, 0);
      onNext();
    } else {
      window.scrollTo(0, 0);
      setErrorText(data?.message || "Internal server error");
     
    }

    setLoading(false);
  };

  return (
    <div>
      <div className="w-100">
        <div className="pb-10 pb-lg-15">
          <h2 className="fw-bold text-dark">Your bank details</h2>
          <div className="text-muted fw-semibold fs-6">
            So we can process your bonus
          </div>
        </div>

        {errorText && (
          <div className="mb-5" style={{ marginTop: "-2.5rem" }}>
            <ErrorMessage errorText={errorText} />
          </div>
        )}

        <div className="mb-10 fv-row">
          <label className="form-label mb-3 required">
            Account Holder Name
          </label>
          <input
            type="text"
            className="form-control form-control-lg form-control-solid"
            name="account_name"
            placeholder="Enter the account holder name"
            value={accountHolderName}
            onChange={(e) => {
              if (e.target.value.length <= 150) {
                setAccountHolderName(e.target.value);
              }
              setError({
                ...error,
                accountHolderName: "",
              });
            }}
          />
          {error?.accountHolderName && (
            <Error error={error?.accountHolderName} />
          )}
        </div>
        <div className="mb-10 fv-row">
          <label className="form-label mb-3 required">Name of your bank:</label>
          <input
            type="text"
            className="form-control form-control-lg form-control-solid"
            name="account_name"
            placeholder="Enter Bank name"
            value={bankName}
            onChange={(e) => {
              if (e.target.value.length <= 150) {
                setBankName(e.target.value);
              }
              setError({
                ...error,
                bankName: "",
              });
            }}
          />
          {error?.bankName && <Error error={error?.bankName} />}
        </div>
        <div className="mb-10 fv-row">
          <label className="form-label mb-3 required">Account number:</label>
          <input
            type="text"
            className="form-control form-control-lg form-control-solid"
            name="account_name"
            placeholder="Enter the bank account number"
            value={accountNumber}
            onChange={(e) => {
              let value = e.target.value.replace(/[^0123456789]/g, "");
              if (value.length <= 50) {
                setAccountNumber(value);
              }
              setError({
                ...error,
                accountNumber: "",
              });
            }}
          />
          {error?.accountNumber && <Error error={error?.accountNumber} />}
        </div>
        <div className="mb-10 fv-row">
          <label className="form-label mb-3 required">
            Re-Type Your Account number:
          </label>
          <input
            type="password"
            className="form-control form-control-lg form-control-solid"
            name="account_name"
            placeholder="Re-type the account number"
            value={retypeAccountNumber}
            onChange={(e) => {
              let value = e.target.value.replace(/[^0123456789]/g, "");
              if (value.length <= 50) {
                setReTypeAccountNumber(value);
              }
              setError({
                ...error,
                reTypeAccountNumber: "",
              });
            }}
          />
          {error?.retypeAccountNumber && (
            <Error error={error?.retypeAccountNumber} />
          )}
        </div>
        <div className="mb-10 fv-row">
          <label className="form-label mb-3 required">IFSC code</label>
          <input
            type="text"
            className="form-control form-control-lg form-control-solid"
            name="account_name"
            placeholder="Enter IFSC code"
            value={ifsc}
            onChange={(e) => {
              if (e.target.value.length <= 25) {
                setIfsc(e.target.value);
              }
              setError({
                ...error,
                ifsc: "",
              });
            }}
          />
          {error?.ifsc && <Error error={error?.ifsc} />}
        </div>
        <div className="mb-10 fv-row">
          <label className="form-label mb-3 required">Pan Card number</label>
          <input
            type="text"
            className="form-control form-control-lg form-control-solid"
            name="pan_card"
            placeholder="Enter the pan card number"
            value={panNumber}
            onChange={(e) => {
              if (e.target.value.length <= 25) {
                setPanCard(e.target.value);
              }
              setError({
                ...error,
                panCard: "",
              });
            }}
          />
          {error?.panCard && <Error error={error?.panCard} />}
        </div>
      </div>

      <Footer
        onPrev={onPrev}
        nextText="Save Bank Details"
        onNext={saveBankDetails}
        loading={loading}
      />
    </div>
  );
};

export default BankAccount;
