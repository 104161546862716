import React, { useRef, useState } from "react";
import Footer from "./footer";
import Error from "../common/error";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import Location from "./location";
import ErrorMessage from "../common/errorMessage";

const Lead = ({ onPrev, onNext, token, referrerDetails }) => {
  console.log('referrerDetails', referrerDetails);
  const [loading, setLoading] = useState(false);

  const pinCodeRef = useRef(false);
  const htmlError = useRef(false);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState();
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState();
  const [monthlyBill, setMontlyBill] = useState();

  const [error, setError] = useState({});
  const [errorText, setErrorText] = useState("");

  const canSubmit = () => {
    let temp = {};

    if (!(name && name.trim())) {
      temp.name = "Please enter Name";
    } else if (name.trim().length < 3) {
      temp.name = "Name should be atleast 3 characters length";
    }

    if (!mobile) {
      temp.mobile = "Please enter phone number";
    } else if (!(mobile && mobile.length === 10)) {
      temp.mobile = "Please enter a valid phone number";
    }

    if (!state || !city) {
      temp.location = "Please enter valid area, city or pincode";
    }

    if (!location) {
      temp.location = "Please enter location";
    }

    if (email && email.trim()) {
      var validations = {
        email: [
          /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/, // eslint-disable-line
        ],
      };
      let validation = new RegExp(validations["email"][0]);
      if (!validation.test(email)) {
        temp.email = "Please enter a valid email";
      }
    }

    if (!monthlyBill) {
      temp.monthlyBill = "Please select one of the options";
    }
    setError(temp);

    return Object.keys(temp).length > 0 ? false : true;
  };

  const changePincode = (pinCode) => {
    if (pinCode) {
      pinCodeRef.current = true;
    } else {
      pinCodeRef.current = false;
    }
    setPincode(pinCode);
  };

  const referLead = async () => {
    if (loading) {
      return;
    }

    if (!canSubmit()) {
      return;
    }

    let payload = {
      name: name.trim(),
      mobile,
      location,
      state,
      city,
      monthlyBill,
      sseid: referrerDetails?.sseid || "",
    };

    if (email && email.trim()) {
      payload.email = email.trim();
    }

    // if (address && address.trim()) {
    //   payload.address = address.trim();
    // }

    if (pincode) {
      payload.pincode = pincode;
    }

    setLoading(true);
    console.log('payload', payload);

    const data = await Api.doFetch("POST", payload, ApiUrl.CSAT_REFERRAL, token);

    if (data?.status) {
      window.scrollTo(0, 0);
      data?.data === 5000 ? onNext(data?.message) : onNext();
    } else {
      window.scrollTo(0, 0);
      if (data?.data === 5000) {
        htmlError.current = true;
      }
      setErrorText(data?.message || "Internal server error");
    }

    setLoading(false);
  };

  return (
    <div>
      <div className="w-100">
        <div className="pb-6">
          <h2 className="fw-bold text-dark">Referral details</h2>
          <div className="text-muted fw-semibold fs-6">
            Enter details of family or friend you want to refer
          </div>
        </div>
        {errorText && (
          <div className="mb-5">
            <ErrorMessage isHtml={htmlError.current} errorText={errorText} />
          </div>
        )}
        <div className="mb-10 fv-row">
          <label className="form-label mb-3 required">Full name</label>
          <input
            type="text"
            className="form-control form-control-lg form-control-solid"
            name="account_name"
            placeholder="Enter full name"
            value={name}
            onChange={(e) => {
              if (e.target.value.length <= 75) {
                setName(e.target.value);
              }
              setError({
                ...error,
                name: "",
              });
            }}
          />
          {error?.name && <Error error={error?.name} />}
        </div>
        <div className="mb-10 fv-row">
          <label className="form-label mb-3 required">Phone number</label>
          <input
            type="text"
            className="form-control form-control-lg form-control-solid"
            name="account_name"
            placeholder="Enter phone number"
            value={mobile}
            onChange={(e) => {
              let value = e.target.value.replace(/[^0123456789]/g, "");
              if (value.length <= 10) {
                setMobile(value);
              }
              setError({
                ...error,
                mobile: "",
              });
            }}
          />
          {error?.mobile && <Error error={error?.mobile} />}
        </div>
        <div className="mb-10 fv-row">
          <label className="form-label mb-3">Email address</label>
          <input
            type="text"
            className="form-control form-control-lg form-control-solid"
            name="account_name"
            placeholder="Enter email address"
            value={email}
            onChange={(e) => {
              if (e.target.value.length <= 75) {
                setEmail(e.target.value);
              }
              setError({
                ...error,
                email: "",
              });
            }}
          />
          {error?.email && <Error error={error?.email} />}
        </div>
        <div className="fv-row mb-10">
          <label className="d-flex align-items-center form-label">
            <span className="required">Address / Area / Pincode</span>
          </label>
          <label className="d-flex align-items-center form-label">
            <span>
              <div className="text-muted fw-semibold">
                Our team will contact the user to get their exact address
              </div>
            </span>
          </label>

          <Location
            setLocation={setLocation}
            setState={setState}
            setCity={setCity}
            setPincode={changePincode}
          />
          {error?.location && <Error error={error?.location} />}
        </div>
        {!pinCodeRef.current && location && (
          <div className="mb-10 fv-row">
            <label className="form-label mb-3">Pincode</label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid"
              name="account_name"
              placeholder="Enter pincode"
              value={pincode}
              onChange={(e) => {
                if (e.target.value.length <= 6) {
                  setPincode(e.target.value);
                }
                setError({
                  ...error,
                  pincode: "",
                });
              }}
            />
            {error?.pincode && <Error error={error?.pincode} />}
          </div>
        )}

        <div className="mb-10 fv-row">
          <label className="d-flex align-items-center form-label mb-3">
            <span className="required">Monthly electricity bill</span>
          </label>

          <div className="mb-2 monthly-bill-wrapper" data-kt-buttons="true">
            {["< 1500", "1500-2500", "2500-4000", "4000-8000", "> 8000"].map(
              (data, i) => {
                return (
                  <div key={i}>
                    <label
                      className={`btn btn-outline btn-outline-dashed btn-active-light-primary w-100 py-3 px-4 ${data === monthlyBill ? "active" : ""
                        }`}
                      onClick={() => setMontlyBill(data)}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <input
                        type="radio"
                        className="btn-check"
                        name="account_team_size"
                        value="1-1"
                      />
                      <span className="fw-bold fs-4">{data}</span>
                    </label>
                  </div>
                );
              }
            )}
          </div>
          {error?.monthlyBill && <Error error={error?.monthlyBill} />}
        </div>
      </div>
      <Footer
        nextText={"Refer Now"}
        onPrev={onPrev}
        onNext={referLead}
        loading={loading}
        showPrev={false}
      />
    </div>
  );
};

export default Lead;
