import React from "react";

const ErrorMessage = ({ errorText }) => {
  return (
    <div className="alert alert-custom alert-light-danger alert-dismissible">
      <div className="alert-text">{errorText}</div>
    </div>
  );
};

export default ErrorMessage;
