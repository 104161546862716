import jwt_decode from "jwt-decode";

const tokenName = "robo-code-token";

async function handleTokens(response) {
	const token = response.headers.get("x-auth-token");
	token && localStorage.setItem(tokenName, token);
	return response;
}


async function handleErrors(response) {
	if (response.error_code === 1001) {
		Api().logOut();

		setTimeout(() => {
			window.location.href = "/";
		}, 1000);

		return response;
	}

	return response;
}

function Api() {

	const logIn = async (token) => {
		localStorage.setItem(tokenName, token);
	};

	const logOut = () => {
		localStorage.removeItem(tokenName);
	};

	const isAuthenticated = () => {
		return localStorage.getItem(tokenName);
	};

	const getDecodedToken = () => {
		const token = localStorage.getItem(tokenName);
		if (!token) {
			return {};
		}
		const data = jwt_decode(token);
		return data;
	};

	const getName = () => {
		const data = getDecodedToken();
		return data.name;
	};

	const doFetch = async (type, data, url, tempToken = "") => {
		let reqData = { ...data };
		if (data === null || data === undefined) {
			reqData = {};
		}

		let token = "";
		try {
			token = localStorage.getItem(tokenName);
		} catch (e) {
			token = "";
		}
		return fetch(url, {
			method: type,
			headers:
				type !== "GET"
					? {
						"x-auth-token": tempToken || token,
						"content-type": "Application/json",
						"X-Tenant-Id": process.env.REACT_APP_X_TENANT_ID
					}
					: {
						"x-auth-token": tempToken || token,
						"X-Tenant-Id": process.env.REACT_APP_X_TENANT_ID
					},
			body: type !== "GET" ? JSON.stringify({ ...reqData }) : null,
		})
			.then(handleTokens)
			.then((res) => res.json())
			.then(handleErrors)
			.catch((err) => console.log(err));
	};

	return {
		logIn,
		logOut,
		doFetch,
		getName,
		isAuthenticated,
		getDecodedToken
	};
}

export default Api();
