import React, { useRef, useState } from "react";
import Layout from "../layout";
import Lead from "../referralCX/lead";
import Login from "./login";
import Success from "../referralCX/success";

const Referral = () => {
  const [step, setStep] = useState(1);
  const [token, setToken] = useState("");
  const response = useRef();

  function onNext(data) {
    setStep(3);
    response.current = data;
  }
  return (
    <div
      className="d-flex flex-column flex-root"
      id="kt_app_root"
      style={{ height: "100vh" }}
    >
      <div
        className="d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep"
        id="kt_create_account_stepper"
      >
        <Layout />

        <div className="d-flex flex-column flex-lg-row-fluid py-10">
          <div className="d-flex flex-center flex-column flex-column-fluid">
            <div className="w-lg-650px w-xl-700px p-10 p-lg-15 mx-auto">
              {step === 1 ? (
                <Login
                  onNext={(data) => {
                    setToken(data);
                    setStep(2);
                  }}
                />
              ) : step === 2 ? (
                <Lead
                  onNext={onNext}
                  onPrev={() => {
                    setToken("");
                    setStep(1);
                  }}
                  token={token}
                />
              ) : (
                <Success
                  onPrev={() => {
                    setStep(1)
                  }}
                  errorText={response.current}
                  onNext={() => {
                    setStep(2);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Referral;
