import React, { useState } from "react";
import Footer from "./footer";
import Error from "../common/error";
import { REFERRAL_TYPE } from "../../utils/constants";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import ErrorMessage from "../common/errorMessage";
import { Select } from "antd";

const { Option } = Select;

const solarSquareRoles = [
  "Solar Consultant",
  "CDM",
  "RM",
  "Inbound cc team",
  "Ops(project/liasing/O&M/others)",
  "SC - Referral Calling",
  "HO Team & Others",
];

const employeeRoles = ["CDM", "Ops(project/liasing/O&M/others)"];

const Login = ({ onNext }) => {
  const [customer, setCustomer] = useState(true);
  const [employeeReferral, setEmployeeReferral] = useState(false);

  const [loading, setLoading] = useState(false);

  const [mobile, setMobile] = useState("");
  const [orderId, setOrderId] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");

  const [error, setError] = useState({});

  const [errorText, setErrorText] = useState("");

  const canSubmit = () => {
    setOrderId("");

    let temp = {};

    // if (!mobile) {
    // 	temp.mobile = "Please enter Phone number";
    // } else if (!(mobile && mobile.length === 10)) {
    // 	temp.mobile = "Please enter a valid phone number";
    // }

    if (!(email && email.trim())) {
      temp.email = "Please enter Email";
    } else {
      var validations = {
        email: [
          /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/, // eslint-disable-line
        ],
      };
      let validation = new RegExp(validations["email"][0]);
      if (!validation.test(email)) {
        temp.email = "Please enter a valid Email";
      }
    }

    if (!role) {
      temp.role = "Please select Role";
    }

    setError(temp);

    return Object.keys(temp).length > 0 ? false : true;
  };

  const canSubmitCustomer = () => {
    let temp = {};

    if (!mobile) {
      temp.mobile = "Please enter Phone number";
    } else if (!(mobile && mobile.length === 10)) {
      temp.mobile = "Please enter a valid phone number";
    }

    setError(temp);

    return Object.keys(temp).length > 0 ? false : true;
  };

  const loginCustomer = async () => {
    if (!canSubmitCustomer()) {
      return;
    }

    let payload = {
      mobile,
      type: REFERRAL_TYPE.EXISTING_CUSTOMER,
    };

    if (orderId && orderId.trim()) {
      payload.orderId = orderId.trim();
    }

    setLoading(true);

    const data = await Api.doFetch("POST", payload, ApiUrl.REFERRAL_LOGIN);

    if (data?.status) {
      setCustomer(false);
      setErrorText();
    } else {
      setErrorText(data?.message || "Internal server error");
    }

    setLoading(false);
  };

  const login = async () => {
    if (!canSubmit()) {
      return;
    }

    let payload = {
      email,
      role,
      source: "Referral - Existing Cx via Emp",
      type: employeeReferral
        ? REFERRAL_TYPE.SSE_EMPLOYEE
        : REFERRAL_TYPE.SOLAR_SQUARE,
    };

    if (payload.type != REFERRAL_TYPE.SSE_EMPLOYEE) {
      payload["mobile"] = mobile;
    }
    
    if (orderId && orderId.trim()) {
      payload.orderId = orderId.trim();
    }

    setLoading(true);

    const data = await Api.doFetch("POST", payload, ApiUrl.REFERRAL_LOGIN);

    if (data?.status) {
      window.scrollTo(0, 0);
      onNext(data?.data);
    } else {
      window.scrollTo(0, 0);
      setErrorText(data?.message || "Internal server error");
    }

    setLoading(false);
  };

  return (
    <div>
      <div className="w-100">
        <div className="pb-10 pb-lg-12">
          <h2 className="fw-bold text-dark">
            {customer && !employeeReferral
              ? "Share existing Cx registered mobile"
              : "Share your SolarSquare details"}
          </h2>

          {/* <div className="text-muted fw-semibold fs-6">
						If you need more info, please check out
						<span className="link-primary fw-bold"> Help Page</span>.
					</div> */}
        </div>

        {errorText && (
          <div className="mb-5" style={{ marginTop: "-2rem" }}>
            <ErrorMessage errorText={errorText} />
          </div>
        )}

        {customer && !employeeReferral ? (
          <div className="fv-row mb-10">
            <label className="d-flex align-items-center form-label">
              <span className="required">Customer phone number</span>
              {/* <i
								className="fas fa-exclamation-circle ms-2 fs-7"
								data-bs-toggle="popover"
								data-bs-trigger="hover"
								data-bs-html="true"
								data-bs-content="&lt;div className='p-4 rounded bg-light'&gt; &lt;div className='d-flex flex-stack text-muted mb-4'&gt; &lt;i className='fas fa-university fs-3 me-3'&gt;&lt;/i&gt; &lt;div className='fw-bold'&gt;INCBANK **** 1245 STATEMENT&lt;/div&gt; &lt;/div&gt; &lt;div className='d-flex flex-stack fw-semibold text-gray-600'&gt; &lt;div&gt;Amount&lt;/div&gt; &lt;div&gt;Transaction&lt;/div&gt; &lt;/div&gt; &lt;div className='separator separator-dashed my-2'&gt;&lt;/div&gt; &lt;div className='d-flex flex-stack text-dark fw-bold mb-2'&gt; &lt;div&gt;USD345.00&lt;/div&gt; &lt;div&gt;KEENTHEMES*&lt;/div&gt; &lt;/div&gt; &lt;div className='d-flex flex-stack text-muted mb-2'&gt; &lt;div&gt;USD75.00&lt;/div&gt; &lt;div&gt;Hosting fee&lt;/div&gt; &lt;/div&gt; &lt;div className='d-flex flex-stack text-muted'&gt; &lt;div&gt;USD3,950.00&lt;/div&gt; &lt;div&gt;Payrol&lt;/div&gt; &lt;/div&gt; &lt;/div&gt;"
							></i> */}
            </label>

            <input
              name="business_descriptor"
              className="form-control form-control-lg form-control-solid"
              placeholder="Please type customer phone number"
              value={mobile}
              onChange={(e) => {
                let value = e.target.value.replace(/[^0123456789]/g, "");
                if (value.length <= 10) {
                  setMobile(value);
                }
                setError({
                  ...error,
                  mobile: "",
                });
              }}
            />

            {/* <div className="form-text">Customers will see this shortened version of your statement descriptor</div> */}

            {error?.mobile && <Error error={error?.mobile} />}
          </div>
        ) : (
          <>
            <div className="fv-row mb-10">
              <label className="d-flex align-items-center form-label">
                <span className="required">Email</span>
                {/* <i 
									className="fas fa-exclamation-circle ms-2 fs-7"
									data-bs-toggle="popover"
									data-bs-trigger="hover"
									data-bs-html="true"
									data-bs-content="&lt;div className='p-4 rounded bg-light'&gt; &lt;div className='d-flex flex-stack text-muted mb-4'&gt; &lt;i className='fas fa-university fs-3 me-3'&gt;&lt;/i&gt; &lt;div className='fw-bold'&gt;INCBANK **** 1245 STATEMENT&lt;/div&gt; &lt;/div&gt; &lt;div className='d-flex flex-stack fw-semibold text-gray-600'&gt; &lt;div&gt;Amount&lt;/div&gt; &lt;div&gt;Transaction&lt;/div&gt; &lt;/div&gt; &lt;div className='separator separator-dashed my-2'&gt;&lt;/div&gt; &lt;div className='d-flex flex-stack text-dark fw-bold mb-2'&gt; &lt;div&gt;USD345.00&lt;/div&gt; &lt;div&gt;KEENTHEMES*&lt;/div&gt; &lt;/div&gt; &lt;div className='d-flex flex-stack text-muted mb-2'&gt; &lt;div&gt;USD75.00&lt;/div&gt; &lt;div&gt;Hosting fee&lt;/div&gt; &lt;/div&gt; &lt;div className='d-flex flex-stack text-muted'&gt; &lt;div&gt;USD3,950.00&lt;/div&gt; &lt;div&gt;Payrol&lt;/div&gt; &lt;/div&gt; &lt;/div&gt;"
								></i> */}
              </label>

              <input
                name="business_descriptor"
                className="form-control form-control-lg form-control-solid"
                placeholder="Please type email"
                value={email}
                onChange={(e) => {
                  if (e.target.value.length <= 75) {
                    setEmail(e.target.value);
                  }
                  setError({
                    ...error,
                    email: "",
                  });
                }}
              />
              {error?.email && <Error error={error?.email} />}
            </div>

            <div className="fv-row mb-10">
              <label className="form-label required">Role</label>
              <br />
              <Select
                style={{ width: "100%" }}
                size={"large"}
                className="referral_select"
                placeholder="Please select role"
                value={role || undefined}
                onChange={(value) => {
                  setRole(value);
                  setError({
                    ...error,
                    role: "",
                  });
                }}
              >
                {(employeeReferral ? employeeRoles : solarSquareRoles).map(
                  (data, i) => {
                    return (
                      <Option key={i} value={data}>
                        {data}
                      </Option>
                    );
                  }
                )}
              </Select>
              {error?.role && <Error error={error?.role} />}
            </div>
          </>
        )}

        {/* <div className="fv-row mb-10">

					<label className="d-flex align-items-center form-label">
						<span>Order ID</span>
						<i
							className="fas fa-exclamation-circle ms-2 fs-7"
							data-bs-toggle="popover"
							data-bs-trigger="hover"
							data-bs-html="true"
							data-bs-content="&lt;div className='p-4 rounded bg-light'&gt; &lt;div className='d-flex flex-stack text-muted mb-4'&gt; &lt;i className='fas fa-university fs-3 me-3'&gt;&lt;/i&gt; &lt;div className='fw-bold'&gt;INCBANK **** 1245 STATEMENT&lt;/div&gt; &lt;/div&gt; &lt;div className='d-flex flex-stack fw-semibold text-gray-600'&gt; &lt;div&gt;Amount&lt;/div&gt; &lt;div&gt;Transaction&lt;/div&gt; &lt;/div&gt; &lt;div className='separator separator-dashed my-2'&gt;&lt;/div&gt; &lt;div className='d-flex flex-stack text-dark fw-bold mb-2'&gt; &lt;div&gt;USD345.00&lt;/div&gt; &lt;div&gt;KEENTHEMES*&lt;/div&gt; &lt;/div&gt; &lt;div className='d-flex flex-stack text-muted mb-2'&gt; &lt;div&gt;USD75.00&lt;/div&gt; &lt;div&gt;Hosting fee&lt;/div&gt; &lt;/div&gt; &lt;div className='d-flex flex-stack text-muted'&gt; &lt;div&gt;USD3,950.00&lt;/div&gt; &lt;div&gt;Payrol&lt;/div&gt; &lt;/div&gt; &lt;/div&gt;"
						></i>
					</label>

					<input
						name="business_descriptor"
						className="form-control form-control-lg form-control-solid"
						placeholder="Please type Order id"
						value={orderId}
						onChange={e => {
							setOrderId(e.target.value);
							setError({
								...error,
								orderId: ""
							})
						}}
					/>


					<div className="form-text">Customers will see this shortened version of your statement descriptor</div>

				</div> */}
      </div>

      <Footer
        onPrev={() => {
          setEmployeeReferral(true);
        }}
        showPrev={!employeeReferral && customer}
        showPrevIcon={false}
        prevText="Employee referral"
        onNext={() => {
          if (customer && !employeeReferral) {
            loginCustomer();
          } else {
            login();
          }
        }}
        loading={loading}
      />
    </div>
  );
};

export default Login;
