import React, { useState } from "react";
import Footer from "./footer";
import { useNavigate } from "react-router-dom";
import Error from "../common/error";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import ErrorMessage from "../common/errorMessage";

const Login = ({ mobile, setMobile, onNext }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState({});
  const [errorText, setErrorText] = useState("");

  const canSubmit = () => {
    let temp = {};

    if (!mobile) {
      temp.mobile = "Please enter Phone number";
    } else if (!(mobile && mobile.length === 10)) {
      temp.mobile = "Please enter a valid phone number";
    }

    setError(temp);

    return Object.keys(temp).length > 0 ? false : true;
  };

  const login = async () => {
    if (!canSubmit()) {
      return;
    }

    let payload = {
      mobile,
    };

    setLoading(true);

    const data = await Api.doFetch("POST", payload, ApiUrl.CHECK_STATUS_LOGIN);

    if (data?.status) {
      onNext(data?.data?.sessionId);
    } else {
      setErrorText(data?.message || "Internal server error");
    }

    setLoading(false);
  };

  return (
    <div>
      <div className="w-100">
        <div className="pb-10 pb-lg-12">
          {/* <h2 className="fw-bold text-dark">Check Referal Status</h2> */}

          {/* <div className="text-muted fw-semibold fs-6">
                        Please enter the mobile number which you have used while referring your contact.
                    </div> */}
        </div>

        <div className="fv-row mb-10">
          <label className="form-label">
            <span className="required">Registered phone number</span>
          </label>
          {/* <div>
            <label className="form-label">
              <div className="text-muted fw-semibold fs-6">
                <span>(Log in with the number you registered with us) </span>
              </div>
            </label>
          </div> */}

          <input
            name="business_name"
            className="form-control form-control-lg form-control-solid"
            placeholder="Enter your phone number"
            value={mobile}
            onChange={(e) => {
              let value = e.target.value.replace(/[^0123456789]/g, "");
              if (value.length <= 10) {
                setMobile(value);
              }
              setError({
                ...error,
                mobile: "",
              });
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                login();
              }
            }}
          />
        </div>
        {error?.mobile && <Error error={error?.mobile} />}
      </div>

      {errorText && (
        <div className="mt-3">
          <ErrorMessage errorText={errorText} />
        </div>
      )}

      <Footer onPrev={() => navigate("/")} onNext={login} loading={loading} />
    </div>
  );
};

export default Login;
