import React, { useEffect, useState } from "react";
import Footer from "./footer";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import swal from "sweetalert";
import Loader from "../common/loader";
import { Tabs } from "antd";
import DefaultImg from "../../assets/img/default_img.png";
import moment from "moment";

const Referral = ({ referrals, onNext, search, setSearch }) => {
  let referralData = JSON.parse(JSON.stringify(referrals));

  if (search) {
    referralData = referralData.filter((x) => {
      return (
        x?.mobile.includes(search) || new RegExp(search, "i").test(x?.name)
      );
    });
  }

  return (
    <>
      <div>
        <i className="fa fa-search search_referral_icon" />
        <input
          style={{
            width: "100%",
          }}
          type="text"
          className="form-control form-control-lg form-control-solid search_referral"
          name="account_name"
          placeholder="Search by name or phone number"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      {referralData.length === 0 ? (
        <div className="text-center mt-5 mb-5">
          <span className="card-label fw-bold fs-3 mb-1">
            No existing referrals found!
          </span>
        </div>
      ) : (
        <div className="referral_list_wrapper mt-4">
          {referralData.map((data, i) => {
            return (
              <div
                className="card-body py-3"
                style={{ background: "#eee", marginBottom: "10px" }}
                key={i}
              >
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="kt_table_widget_5_tab_1"
                    role="tabpanel"
                  >
                    <div className="table-responsive">
                      <table
                        className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4"
                        style={{ marginBottom: "0px!important" }}
                      >
                        <thead>
                          <tr className="border-0">
                            <th className="p-0 min-w-200px"></th>
                            <th className="p-0 min-w-140px"></th>
                            <th className="p-0 min-w-110px"></th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>
                              <span className="text-dark fw-bold text-hover-primary mb-1 fs-6">
                                {data?.name}
                              </span>
                              <span className="text-muted fw-semibold d-block">
                                {moment(data?.createdAt).format("DD-MM-YYYY")}
                              </span>
                              {
                                <span className="text-muted fw-semibold d-block">
                                  {`${
                                    data?.isPaid
                                      ? "Referral Fee Paid"
                                      : [
                                          "Installation Pending",
                                          "Installation Done",
                                          "Commissioning Done",
                                        ].includes(data?.stage)
                                      ? "Referral Fee Due"
                                      : ""
                                  }`}
                                </span>
                              }
                            </td>
                            <td className="text-end">
                              <span
                                className={`badge ${
                                  [
                                    "Installation Pending",
                                    "Installation Done",
                                    "Commissioning Done",
                                  ].includes(data?.stage)
                                    ? "badge-light-success"
                                    : [
                                        "Cancelled",
                                        "Not Interested",
                                        "Not Eligible",
                                        "Not Reachable",
                                      ].includes(
                                        data?.stage ?? "".toUpperCase()
                                      )
                                    ? "badge-light-danger"
                                    : "badge-light-warning"
                                } `}
                              >
                                {data?.stage}
                              </span>
                            </td>
                            <td className="text-end">
                              <span
                                className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                                onClick={() => {
                                  onNext(data?._id);
                                }}
                              >
                                <span className="svg-icon svg-icon-2">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      opacity="0.5"
                                      x="18"
                                      y="13"
                                      width="13"
                                      height="2"
                                      rx="1"
                                      transform="rotate(-180 18 13)"
                                      fill="currentColor"
                                    ></rect>
                                    <path
                                      d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </span>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {/* <div className="p-relative">
				<i className="fa fa-search search_referral_icon" />
				<input 
					type="text" 
					className="form-control form-control-lg form-control-solid search_referral1" 
					name="account_name" 
					placeholder="Search by phone/order id"
				/>
			</div> */}
    </>
  );
};

const ReferralList = ({
  token,
  referrals,
  setReferrals,
  onPrev,
  onNext,
  selectedTab,
  setSelectedTab,
  search,
  setSearch,
}) => {
  const [loading, setLoading] = useState(false);

  const getReferralList = async () => {
    if (referrals.length === 0) {
      setLoading(true);
    }

    const data = await Api.doFetch("GET", {}, ApiUrl.REFERRAL, token);

    if (data?.status) {
      setReferrals(data?.data);
    } else {
      swal("Error", data?.message || "Internal server error", "error");
    }

    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getReferralList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const items = [
    {
      key: "1",
      label: `All`,
      children: (
        <Referral
          referrals={[
            ...referrals["PENDING"],
            ...referrals["IN_PROGRESS"],
            ...referrals["COMPLETED"],
          ]}
          onNext={onNext}
          search={search}
          setSearch={setSearch}
        />
      ),
    },
    {
      key: "2",
      label: `Open`,
      children: (
        <Referral
          referrals={referrals["PENDING"]}
          onNext={onNext}
          setSearch={setSearch}
          search={search}
        />
      ),
    },
    {
      key: "3",
      label: `Order in progress`,
      children: (
        <Referral
          referrals={referrals["IN_PROGRESS"]}
          onNext={onNext}
          setSearch={setSearch}
          search={search}
        />
      ),
    },
    {
      key: "4",
      label: `Referral success`,
      children: (
        <Referral
          referrals={referrals["COMPLETED"]}
          onNext={onNext}
          setSearch={setSearch}
          search={search}
        />
      ),
    },
  ];

  const onChange = (key) => {
    setSelectedTab(key);
  };

  return (
    <div>
      <div className="w-100">
        <div className="card card-xl-stretch mb-xl-8">
          <div className="card-header border-0 p-0 d-flex flex-wrap referral_status_header">
            <div>
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">
                  My referrals
                </span>
                <span className="text mt-1 fw-semibold fs-7 text-primary">
                  Total earnings: ₹
                  {(referrals?.totalEarnings ?? 0) +
                    (referrals?.totalAmountDue ?? 0)}
                </span>
                <span className="text mt-1  fw-semibold fs-7 text-success">
                  Total credited: ₹{referrals?.totalEarnings ?? 0}
                </span>
              </h3>
            </div>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <Tabs
              activeKey={selectedTab}
              items={items}
              onChange={onChange}
              tabPosition={"top"}
            />
          )}

          {/* <div className="card-body py-3" style={{ background: "#eee", marginBottom: "10px" }}>
						<div className="tab-content">


							<div className="tab-pane fade show active" id="kt_table_widget_5_tab_1" role="tabpanel">

								<div className="table-responsive">

									<table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4" style={{ marginBottom: "0px!important" }} >

										<thead>
											<tr className="border-0">
												<th className="p-0 w-50px"></th>
												<th className="p-0 min-w-150px"></th>
												<th className="p-0 min-w-140px"></th>
												<th className="p-0 min-w-110px"></th>
												<th className="p-0 min-w-50px"></th>
											</tr>
										</thead>



										<tbody>
											<tr>
												<td>
													<div>
														<span>
															<img src="/assets/media/avatars/300-1.jpg" style={{ width: " 48px" }} className="h-50 align-self-center" alt="" />
														</span>
													</div>
												</td>
												<td>
													<span className="text-dark fw-bold text-hover-primary mb-1 fs-6">Ajay Gupta</span>
													<span className="text-muted fw-semibold d-block">24hr ago</span>
												</td>
												<td className="text-end text-muted fw-bold">
													5 KW                                        </td>
												<td className="text-end">
													<span className="badge badge-light-success">Approved</span>
												</td>
												<td className="text-end">
													<span className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary">

														<span className="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
															<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="currentColor"></rect>
															<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor"></path>
														</svg>
														</span>
													</span>
												</td>
											</tr>



										</tbody>

									</table>
								</div>

							</div>

						</div>
					</div>
					<div className="card-body py-3" style={{ background: "#eee", marginBottom: "10px" }} >
						<div className="tab-content">


							<div className="tab-pane fade show active" id="kt_table_widget_5_tab_1" role="tabpanel">

								<div className="table-responsive">

									<table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4" style={{ marginBottom: "0px!important" }}>

										<thead>
											<tr className="border-0">
												<th className="p-0 w-50px"></th>
												<th className="p-0 min-w-150px"></th>
												<th className="p-0 min-w-140px"></th>
												<th className="p-0 min-w-110px"></th>
												<th className="p-0 min-w-50px"></th>
											</tr>
										</thead>



										<tbody>
											<tr>
												<td>
													<div>
														<span>
															<img src="/assets/media/avatars/300-3.jpg" style={{ width: "48px" }} className="h-50 align-self-center" alt="" />
														</span>
													</div>
												</td>
												<td>
													<span className="text-dark fw-bold text-hover-primary mb-1 fs-6">Mukul Arora</span>
													<span className="text-muted fw-semibold d-block">2 days ago</span>
												</td>
												<td className="text-end text-muted fw-bold">
													5 KW                                        </td>
												<td className="text-end">
													<span className="badge badge-light-danger">Reject</span>
												</td>
												<td className="text-end">
													<span className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary">

														<span className="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
															<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="currentColor"></rect>
															<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor"></path>
														</svg>
														</span>
													</span>
												</td>
											</tr>




										</tbody>

									</table>
								</div>

							</div>

						</div>
					</div>
					<div className="card-body py-3" style={{ background: "#eee", marginBottom: "10px" }} >
						<div className="tab-content">


							<div className="tab-pane fade show active" id="kt_table_widget_5_tab_1" role="tabpanel">

								<div className="table-responsive">

									<table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4" style={{ marginBottom: "0px!important" }} >

										<thead>
											<tr className="border-0">
												<th className="p-0 w-50px"></th>
												<th className="p-0 min-w-150px"></th>
												<th className="p-0 min-w-140px"></th>
												<th className="p-0 min-w-110px"></th>
												<th className="p-0 min-w-50px"></th>
											</tr>
										</thead>



										<tbody>
											<tr>
												<td>
													<div>
														<span>
															<img src="/assets/media/avatars/300-2.jpg" style={{ width: "48px" }} className="h-50 align-self-center" alt="" />
														</span>
													</div>
												</td>
												<td>
													<span className="text-dark fw-bold text-hover-primary mb-1 fs-6">Leena Shah</span>
													<span className="text-muted fw-semibold d-block">4 days ago</span>
												</td>
												<td className="text-end text-muted fw-bold">
													5 KW                                        </td>
												<td className="text-end">
													<span className="badge badge-light-danger">Reject</span>
												</td>
												<td className="text-end">
													<span className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary">

														<span className="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
															<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="currentColor"></rect>
															<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor"></path>
														</svg>
														</span>
													</span>
												</td>
											</tr>




										</tbody>

									</table>
								</div>

							</div>

						</div>
					</div> */}
        </div>
      </div>

      <Footer
        onPrev={onPrev}
        onNext={onNext}
        showNext={true}
        nextText="Refer more"
        paddingTop="pt-5"
      />
    </div>
  );
};

export default ReferralList;
