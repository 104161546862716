import React from "react";
import Layout from "../layout";
import HomeLayout from "./home";
import { layoutType } from "../../utils/constants";

const Home = () => {
  return (
    <div
      className="d-flex flex-column flex-root"
      id="kt_app_root"
      style={{ height: "100vh" }}
    >
      <div
        className="d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep"
        id="kt_create_account_stepper"
      >
        <Layout showCarousel={true} />

        <div className="d-flex flex-column flex-lg-row-fluid py-10">
          <div className="d-flex flex-center flex-column flex-column-fluid">
            <div className="w-lg-650px w-xl-700px p-10 p-lg-15 mx-auto">
              <HomeLayout />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
