import React, { useEffect, useRef, useState } from "react";
import Layout from "../layout";
import Lead from "./lead";
import Success from "./success";
import { useNavigate, useParams } from "react-router-dom";
import { REFERRAL_TYPE } from "../../utils/constants";
import ApiUrl from "../../common/apiUrl";
import Api from "../../common/api";
import ErrorMessage from "../common/errorMessage";
import Loader from "../common/loader";

const ReferNow = () => {
    const [step, setStep] = useState(0);
    const navigate = useNavigate();
    const [token, setToken] = useState("");
    const [referrerDetails, setReferrerDetails] = useState();
    const [errorText, setErrorText] = useState("");
    const [fetchingDetails, setFetchingDetails] = useState(false);
    const params = useParams();
    const htmlError = useRef(false);

    useEffect(() => {
        if (params.id) {
            (async () => {
                try {
                    setFetchingDetails(true);
                    const data = await Api.doFetch("GET", {}, `${ApiUrl.GET_CSAT_DETAILS}/${params.id}`, 'qT5EIx1A7mvJiQujPQfetAz8mZ9haTbn');
                    if (data?.statusCode === 200) {
                        if (data?.data && Array.isArray(data?.data) && data?.data?.length > 0 && data?.data[0].csat) {
                            setReferrerDetails(data?.data[0].csat);
                        } else {
                            setErrorText("Could not fetch details of referral, Please try again later");
                        }
                        setFetchingDetails(false);
                    } else {
                        setErrorText(data?.message || "Internal server error");
                        setFetchingDetails(false);
                    }
                } catch (e) {
                    setErrorText(e?.message || "Internal server error");
                    setFetchingDetails(false);
                }
            })();
        }
    }, [params.id]);

    useEffect(() => {
        if (referrerDetails?.customer?.whatsappNo) {
            setFetchingDetails(true);
            const payload = {
                mobile: referrerDetails?.customer?.whatsappNo,
                type: REFERRAL_TYPE.EXISTING_CUSTOMER,
            };
            (async () => {
                const data = await Api.doFetch("POST", payload, ApiUrl.REFERRAL_LOGIN_CSAT);
                if (data?.status) {
                    setToken(data?.data);
                    setStep(1);
                } else {
                    setErrorText(data?.message || "Internal server error");
                }
                setFetchingDetails(false);
            })();
        }
    }, [referrerDetails]);

    return (
        <div
            className="d-flex flex-column flex-root"
            id="kt_app_root"
            style={{ height: "100vh" }}
        >
            <div
                className="d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep"
                id="kt_create_account_stepper"
            >
                <Layout />

                <div className="d-flex flex-column flex-lg-row-fluid py-10">
                    <div className="d-flex flex-center flex-column flex-column-fluid">
                        <div className="w-lg-650px w-xl-700px p-10 p-lg-15 mx-auto">
                            {fetchingDetails && (
                                <div className="mb-5">
                                    <Loader />
                                </div>
                            )}
                            {errorText && (
                                <div className="mb-5">
                                    <ErrorMessage isHtml={htmlError.current} errorText={errorText} />
                                </div>
                            )}
                            {step === 1 ? (
                                <Lead
                                    referrerDetails={referrerDetails}
                                    onPrev={() => {
                                        setToken("");
                                        setStep(1);
                                    }}
                                    onNext={() => setStep(2)}
                                    token={token}
                                />
                            ) : step === 2 ? (
                                <Success
                                    onPrev={() => {
                                        navigate("/");
                                    }}
                                    onNext={() => {
                                        setStep(1);
                                    }}
                                />
                            ) : <></>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReferNow;
