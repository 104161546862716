import React, { useState } from "react";
import { REFERRAL, CHECK_STATUS } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
// import ToolTip from "../common/tooltip";

const Home = () => {
  const [type, setType] = useState(REFERRAL);

  const navigate = useNavigate();

  return (
    <div>
      <div className="w-100">
        {/* <div className="pb-10 pb-lg-15"> */}
        {/* <h2 className="fw-bold d-flex align-items-center text-dark"> */}
        {/* Refer a friend or family now They save, you earn */}
        {/* <ToolTip 
                            placement={"top"}
                            text={"Billing is issued based on your selected account type"}
                        >
                            <i 
                                className="fas fa-exclamation-circle ms-2 fs-7"
                            />
                        </ToolTip> */}
        {/* </h2> */}

        {/* <div className="text-muted fw-semibold fs-6">
                        If you need more info, please check your status
                        <span className="link-primary fw-bold"> Check Status</span>.
                    </div> */}
        {/* </div> */}

        <div className="fv-row">
          <div className="row">
            <div>
              <input
                type="radio"
                className="btn-check"
                checked={type === REFERRAL && "checked"}
                readOnly
              />
              <label
                className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center mb-10"
                onClick={() => setType(REFERRAL)}
              >
                <span className="svg-icon svg-icon-3x me-5">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z"
                      fill="currentColor"
                    />
                    <path
                      opacity="0.3"
                      d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>

                <span className="d-block fw-semibold text-start">
                  <span className="text-dark fw-bold d-block fs-4 mb-2">
                    Refer A Friend
                  </span>
                  <span className="text-muted">They save, you earn</span>
                  {/* <span className="text-muted fw-semibold fs-6">If you need more info, please check it out</span> */}
                </span>
              </label>
            </div>

            <div>
              <input
                type="radio"
                className="btn-check"
                checked={type === CHECK_STATUS && "checked"}
                readOnly
              />
              <label
                className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center"
                onClick={() => setType(CHECK_STATUS)}
              >
                <span className="svg-icon svg-icon-3x me-5">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M20 15H4C2.9 15 2 14.1 2 13V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V13C22 14.1 21.1 15 20 15ZM13 12H11C10.5 12 10 12.4 10 13V16C10 16.5 10.4 17 11 17H13C13.6 17 14 16.6 14 16V13C14 12.4 13.6 12 13 12Z"
                      fill="currentColor"
                    />
                    <path
                      d="M14 6V5H10V6H8V5C8 3.9 8.9 3 10 3H14C15.1 3 16 3.9 16 5V6H14ZM20 15H14V16C14 16.6 13.5 17 13 17H11C10.5 17 10 16.6 10 16V15H4C3.6 15 3.3 14.9 3 14.7V18C3 19.1 3.9 20 5 20H19C20.1 20 21 19.1 21 18V14.7C20.7 14.9 20.4 15 20 15Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>

                <span className="d-block fw-semibold text-start">
                  <span className="text-dark fw-bold d-block fs-4 mb-2">
                    Track Referrals
                  </span>
                  <span className="text-muted">
                    Check status of your referrals
                  </span>
                  {/* <span className="text-muted fw-semibold fs-6">Create corporate account to mane users</span> */}
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex flex-end pt-15">
        <div>
          <button
            type="button"
            className="btn btn-lg btn-primary"
            onClick={() => {
              if (type === REFERRAL) {
                navigate("/referral/cx");
              } else {
                navigate("/referral/status");
              }
            }}
          >
            Continue
            <span className="svg-icon svg-icon-4 ms-1">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  opacity="0.5"
                  x="18"
                  y="13"
                  width="13"
                  height="2"
                  rx="1"
                  transform="rotate(-180 18 13)"
                  fill="currentColor"
                />
                <path
                  d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
