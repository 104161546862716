import React, { useState } from "react";
import Login from "./login";
import VerifyOTP from "./verifyOTP";
import ReferralList from "./referralList";
import ReferralDetail from "./referral";
import Layout from "../layout";
import BankAccount from "./bankAccount";
import alert from "../../assets/img/alert.svg";
import { useNavigate } from "react-router-dom";
const ReferralStatus = () => {
  const [step, setStep] = useState(1);

  const [mobile, setMobile] = useState("");
  const [sessionId, setSessionId] = useState("");

  const [token, setToken] = useState("");
  const [bankAccount, setBankAccount] = useState({});
  const [referrals, setReferrals] = useState({
    PENDING: [],
    IN_PROGRESS: [],
    COMPLETED: [],
  });

  const [referralId, setReferralId] = useState("");

  const [selectedTab, setSelectedTab] = useState("1");
  const [bankAccountModal, setBankAccountModal] = useState(false);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  return (
    <div
      className="d-flex flex-column flex-root"
      id="kt_app_root"
      style={{ height: "100vh" }}
    >
      <div
        className="d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep"
        id="kt_create_account_stepper"
      >
        <Layout />

        <div className="d-flex flex-column flex-lg-row-fluid py-10">
          <div className="d-flex flex-center flex-column flex-column-fluid">
            <div className="w-lg-650px w-xl-700px p-10 p-lg-15 mx-auto referral_list_container">
              {step >= 3 && !bankAccount && !bankAccountModal && (
                <div
                  class={`notice d-flex bg-light-warning rounded border-warning border border-dashed p-6  mb-${
                    bankAccountModal || step == 4 ? 10 : 0
                  }`}
                >
                  <div class="d-flex flex-stack flex-grow-1 ">
                    <img
                      className="ki-duotone ki-information fs-2tx text-warning me-4"
                      src={alert}
                    />
                    <div class=" fw-semibold">
                      <h4 class="text-gray-900 fw-bold">
                        We need your attention!
                      </h4>
                      <div class="fs-6 text-gray-700 ">
                        To start receiving referral payments in your account,
                        please{" "}
                        <span
                          onClick={(e) => setBankAccountModal(true)}
                          class="fw-bold"
                          style={{
                            cursor: "pointer",
                            color: "#009ef7",
                          }}
                        >
                          Add Bank Details
                        </span>
                        .
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {bankAccountModal ? (
                <BankAccount
                  bankAccount={bankAccount}
                  token={token}
                  onPrev={() => {
                    setBankAccountModal(false);
                  }}
                  onNext={() => {
                    setBankAccount(true);
                    setBankAccountModal(false);
                  }}
                />
              ) : step === 1 ? (
                <Login
                  mobile={mobile}
                  setMobile={setMobile}
                  onNext={(data) => {
                    setSessionId(data);
                    setStep(2);
                  }}
                />
              ) : step === 2 ? (
                <VerifyOTP
                  mobile={mobile}
                  sessionId={sessionId}
                  setSessionId={setSessionId}
                  onPrev={() => {
                    setStep(step - 1);
                    setSessionId("");
                  }}
                  onNext={(data) => {
                    setToken(data?.token);
                    setBankAccount(data?.bankAccount);
                    setStep(3);
                  }}
                />
              ) : step === 3 ? (
                <ReferralList
                  token={token}
                  referrals={referrals}
                  setReferrals={setReferrals}
                  onPrev={() => setStep(step - 2)}
                  onNext={(data) => {
                    if (data) {
                      setReferralId(data);
                      setStep(step + 1);
                    } else {
                      navigate("/referral/cx");
                    }
                  }}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  search={search}
                  setSearch={setSearch}
                />
              ) : (
                <ReferralDetail
                  token={token}
                  referralId={referralId}
                  onPrev={() => setStep(step - 1)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralStatus;
