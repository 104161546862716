import React from 'react';
import "./App.css";
import 'antd/dist/reset.css';
import Home from './components/home';
import ReferralCX from './components/referralCX';
import ReferralSolarSquare from './components/referralSolarSquare';
import ReferralStatus from './components/referralStatus';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import PageNotFound from './components/pageNotFound/404Page';
import ReferNow from './components/referNow';
import RedirectToPlayStore from './components/redirectToPlayStore';

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/referral/cx" element={<ReferralCX />} />
				<Route path="/referral/solar-square" element={<ReferralSolarSquare />} />
				<Route path="/referral/status" element={<ReferralStatus />} />
				<Route path="/referral/csat/:id" element={<ReferNow/>} />
				<Route path="/referral/refer-now" element={<RedirectToPlayStore/>} />
				<Route path="*" element={<PageNotFound />} />
				{/* <Route element={<AppLayout />}>
					<Route path="auth/*" element={<Auth />} />
					<Route path="/logout" element={<Logout />} />
					<Route path="/*" element={<Layout />} />
				</Route> */}
			</Routes>
		</BrowserRouter>
	);
}

export default App;
