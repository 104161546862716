import React, { useState } from "react";
import Footer from "./footer";
import { useNavigate } from "react-router-dom";
import Error from "../common/error";
import { REFERRAL_TYPE } from "../../utils/constants";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import ErrorMessage from "../common/errorMessage";

const Login = ({ onNext }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [mobile, setMobile] = useState("");
  const [orderId, setOrderId] = useState("");

  const [error, setError] = useState({});

  const [errorText, setErrorText] = useState("");

  const canSubmit = () => {
    setOrderId("");

    let temp = {};

    if (!mobile) {
      temp.mobile = "Please enter Phone number";
    } else if (!(mobile && mobile.length === 10)) {
      temp.mobile = "Please enter a valid phone number";
    }

    setError(temp);

    return Object.keys(temp).length > 0 ? false : true;
  };

  const login = async () => {
    if (!canSubmit()) {
      return;
    }

    let payload = {
      mobile,
      type: REFERRAL_TYPE.EXISTING_CUSTOMER,
    };

    if (orderId && orderId.trim()) {
      payload.orderId = orderId.trim();
    }

    setLoading(true);

    const data = await Api.doFetch("POST", payload, ApiUrl.REFERRAL_LOGIN);

    if (data?.status) {
      window.scrollTo(0, 0);
      onNext(data?.data);
    } else {
      setErrorText(data?.message || "Internal server error");
    }

    setLoading(false);
  };

  return (
    <div>
      <div className="w-100">
        <div className="pb-10 pb-lg-12">
          {/* <h2 className="fw-bold text-dark">Customer Login</h2> */}

          {/* <div className="text-muted fw-semibold fs-6">
						If you need more info, please check out
						<span className="link-primary fw-bold"> Help Page</span>.
					</div> */}
        </div>

        <div className="fv-row mb-10">
          <label className="d-flex align-items-center form-label">
            <span className="required">Registered phone number</span>
          </label>
          

          <input
            name="business_descriptor"
            className="form-control form-control-lg form-control-solid"
            placeholder="Enter your phone number"
            value={mobile}
            onChange={(e) => {
              let value = e.target.value.replace(/[^0123456789]/g, "");
              if (value.length <= 10) {
                setMobile(value);
              }
              setError({
                ...error,
                mobile: "",
              });
            }}
          />

          {/* <div className="form-text">Customers will see this shortened version of your statement descriptor</div> */}

          {error?.mobile && <Error error={error?.mobile} />}
        </div>

        {/* <div className="fv-row mb-10">

					<label className="d-flex align-items-center form-label">
						<span>Order ID</span>
						<i
							className="fas fa-exclamation-circle ms-2 fs-7"
							data-bs-toggle="popover"
							data-bs-trigger="hover"
							data-bs-html="true"
							data-bs-content="&lt;div className='p-4 rounded bg-light'&gt; &lt;div className='d-flex flex-stack text-muted mb-4'&gt; &lt;i className='fas fa-university fs-3 me-3'&gt;&lt;/i&gt; &lt;div className='fw-bold'&gt;INCBANK **** 1245 STATEMENT&lt;/div&gt; &lt;/div&gt; &lt;div className='d-flex flex-stack fw-semibold text-gray-600'&gt; &lt;div&gt;Amount&lt;/div&gt; &lt;div&gt;Transaction&lt;/div&gt; &lt;/div&gt; &lt;div className='separator separator-dashed my-2'&gt;&lt;/div&gt; &lt;div className='d-flex flex-stack text-dark fw-bold mb-2'&gt; &lt;div&gt;USD345.00&lt;/div&gt; &lt;div&gt;KEENTHEMES*&lt;/div&gt; &lt;/div&gt; &lt;div className='d-flex flex-stack text-muted mb-2'&gt; &lt;div&gt;USD75.00&lt;/div&gt; &lt;div&gt;Hosting fee&lt;/div&gt; &lt;/div&gt; &lt;div className='d-flex flex-stack text-muted'&gt; &lt;div&gt;USD3,950.00&lt;/div&gt; &lt;div&gt;Payrol&lt;/div&gt; &lt;/div&gt; &lt;/div&gt;"
						></i>
					</label>

					<input
						name="business_descriptor"
						className="form-control form-control-lg form-control-solid"
						placeholder="Please type Order id"
						value={orderId}
						onChange={e => {
							setOrderId(e.target.value);
							setError({
								...error,
								orderId: ""
							})
						}}
					/>


					<div className="form-text">Customers will see this shortened version of your statement descriptor</div>

				</div> */}
      </div>

      {errorText && (
        <div className="mt-3">
          <ErrorMessage errorText={errorText} />
        </div>
      )}

      <Footer onPrev={() => navigate("/")} onNext={login} loading={loading} />
    </div>
  );
};

export default Login;
