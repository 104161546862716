import React, { useEffect, useState } from "react";
import Footer from "./footer";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import Error from "../common/error";
import ErrorMessage from "../common/errorMessage";

const VerifyOTP = ({
	mobile,
	sessionId,
	setSessionId,
	onPrev,
	onNext
}) => {

	const [loading, setLoading] = useState(false);

	const [OTP, setOTP] = useState("");
	const [interval, setinterval] = useState(60);

	const [error, setError] = useState("");
	const [errorText, setErrorText] = useState("");

	const verifyOTP = async (otp = OTP) => {
		if(otp && otp.length === 4){
			let payload = {
				mobile,
				otp,
				sessionId
			}
	
			setLoading(true);
	
			const data = await Api.doFetch("POST", payload, ApiUrl.CHECK_STATUS_VERIFY_OTP);
	
			if (data?.status) {
				onNext(data?.data);
			} else {
				setErrorText(data?.message || "Internal server error");
				setTimeout(() => {
					setErrorText("");
				}, 2500)
			}
	
			setLoading(false);
        }else{
            if(!otp){
                setError("Please enter OTP");
            }else if(otp.length !== 4){
                setError("Please enter a valid OTP");
            }
        }
	}

	const [sendingOTP, setSendingOTP] = useState(false);
	const sendOTP = async () => {

		let payload = {
			mobile
		}

		setSendingOTP(true);

		const data = await Api.doFetch("POST", payload, ApiUrl.CHECK_STATUS_LOGIN);

		if (data?.status) {
			setinterval(60);
			setSessionId(data?.data?.sessionId);
		} else {
			setErrorText(data?.message || "Internal server error");
			setTimeout(() => {
				setErrorText("");
			}, 2500)
		}

		setSendingOTP(false);
	}

	const handleOTP = (e, index) => {
        let value = e.target.value.replace(/[^0123456789]/g, "");
        value = value.toString().substring(0,4);
    
        if(value){
            setError("");
            let tempOTP = OTP.toString();
            if(tempOTP && tempOTP.length >= index+1){
                tempOTP = tempOTP.substring(index, 0);
            }
            
            tempOTP += value.toString();
          
            setOTP(tempOTP);
            if(index !== 3 && tempOTP.length < 4){
                document.getElementById(`OTP-${tempOTP.length}`).focus();
            }
        }else{
            let tempOTP = OTP.toString();
            if(tempOTP && tempOTP.length >= index+1){
                tempOTP = tempOTP.substring(index, 0);
                setOTP(tempOTP);
                if(index !== 0 && tempOTP && tempOTP.length > index+1){
                    document.getElementById(`OTP-${tempOTP.length}`).focus();
                }
            }
        }
    
        if(value.length === 4){
            verifyOTP(value);
        } else if(index === 3 && value){
            verifyOTP(OTP.toString() + value);
        }
        
    };

	useEffect(() => {
        let myInterval = setInterval(() => {
            if (interval > 0) {
                setinterval(interval - 1);
            }
            if (interval === 0) {
                clearInterval(myInterval);
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    }, [interval]);

	return (
		<div>
			<div className="w-100">
				<div className="text-center mb-10">
					<img alt="Logo" className="mh-125px" src="/assets/media/svg/misc/smartphone-2.svg" />
				</div>


				<div className="text-center mb-10">

					<h1 className="text-dark mb-3">
						Two-step verification
					</h1>

					<div className="text-muted fw-semibold fs-5 mb-5">Enter the verification code sent to</div>

					<div className="fw-bold text-dark fs-3">
						{mobile.replace(/\d(?=\d{4})/g, "*")}
					</div>

				</div>

				{
					errorText &&
					<div className="mb-5" style = {{ marginTop: "-2rem"}} >
						<ErrorMessage
							errorText = {errorText}
						/>
					</div>
				}


				<div className="mb-10 otp_container">

					{/* <div className="fw-bold text-start text-dark fs-6 mb-1 ms-1">Type your 4 digit security code</div> */}

					<div className="d-flex flex-wrap flex-stack">
						{
							[1,2,3,4].map((data,i) => {

								let value = (OTP.toString())[i];
								value = value !== undefined ? value : "";
								
								return(
									<input
										key={i}
										// className={`form-control form-control-solid h-auto py-5 px-3 anthem_book_demo_otp anthem_book_demo_font`}
										className="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"
										type="text"
										name="username"
										value={value}
										onChange={e => handleOTP(e, i)}
										onKeyDown={(e) => {
											if (e.keyCode === 13) {
												verifyOTP();
											}else if(e.keyCode === 8) {
												if(i !== 0){
													if(OTP.length <= i){
														document.getElementById(`OTP-${i-1}`).focus();
													}
												}
											}
										}}
										id={`OTP-${i}`}
										// style = {{ 
										// 	fontSize: "15px",
										// 	borderRadius: "0px",
										// 	// borderColor: value ? "#4E419D" : "",
										// 	borderColor: value ? "#3C6B5E" : "",
										// }}
										autoComplete="off"
									/>
								)
							})
						}
						{error && <Error error={error} />}
					</div>
					<div className="text-center fw-semibold fs-5 d-flex justify-content-between mt-2">
						<div style = {{
							opacity: interval === 0 ? 1 : "0.4",
							cursor: interval === 0 ? "" : "not-allowed",
						}}>
							<span className="text-muted me-1">Didn’t get the code ?</span>

							<span 
								className={`link-primary fs-5 me-1 ${interval === 0 ? sendingOTP ? "not-allowed" : "cursor-pointer" : ""}`}
								onClick={() => {
									if (interval === 0 && !sendingOTP) {
										sendOTP();
									}
								}}
							>
								{
									sendingOTP ?
									"Resending"
									:
									"Resend"
								}
							</span>
						</div>
						<p className="text-muted mb-0">
							{`00:${interval < 10 ? "0" : ""}${interval}`}
						</p>
					</div>

				</div>

			</div>

			<Footer 
				onPrev = {onPrev}
				onNext = {verifyOTP}
				loading = {loading}
			/>

		</div>
	);
};

export default VerifyOTP;