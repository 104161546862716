const host = `${process.env.REACT_APP_BASE_URL}/cms/`;
const host2 = `${process.env.REACT_APP_BASE_URL}/csat/`;

const endpoints = {
	REFERRAL_LOGIN: `${host}referral/customer`,
	REFERRAL_LOGIN_CSAT: `${host}referral/customer/csat`,
	REFERRAL: `${host}referral`,
	CHECK_STATUS_LOGIN: `${host}referral/customer/login`,
	CHECK_STATUS_VERIFY_OTP: `${host}referral/customer/verify-otp`,
	SAVE_BANK_DETAILS: `${host}referral/customer/bank-details`,
	GET_CSAT_DETAILS: `${host2}csat`,
	CSAT_REFERRAL: `${host}referral/csat`,
};

export default endpoints;
