import React from "react";

const Error = ({
	error
}) => {
	return (
		<div class="fv-plugins-message-container invalid-feedback">
			<div data-field="account_name" data-validator="notEmpty">
				{error}
			</div>
		</div>
	)
};

export default Error;