import React from "react";
import LoaderGIF from "../../assets/img/loader.gif";

const Loader = () => {
    return (
        <div className="loader_wrapper">
            <img
                src = {LoaderGIF}
                alt = "Loading..."
                className = "loader_img"
            />
        </div>
    );
};

export default Loader;