import React from "react";
import image1 from "../../assets/img/1.webp";
import image2 from "../../assets/img/2.webp";
import image3 from "../../assets/img/3.webp";
import mobileImage from "../../assets/img/topband.webp";
import desktopImage from "../../assets/img/sidebanddesktop.webp";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./index.css";

const Layout = ({ showCarousel }) => {
  return (
    <div className="d-flex flex-column flex-lg-row-auto w-lg-350px w-xl-500px">
      <div
        className="d-flex flex-column position-lg-fixed top-0 pt-3 bottom-0 w-lg-350px w-xl-500px bgi-size-cover bgi-position-center"
        style={{
          backgroundColor: "#131ca2",
        }}
      >
        {!showCarousel ? (
          <>
            <div className="desktop_img">
              <img src={desktopImage} />
            </div>
            <div className="mobile_img">
              <img src={mobileImage} />
            </div>
          </>
        ) : (
          <Carousel
            autoPlay
            infiniteLoop
            showArrows={false}
            interval={3000}
            showStatus={false}
            showIndicators={true}
            showThumbs={false}
          >
            <div>
              <img src={desktopImage} />
            </div>
            <div>
              <img src={image1} />
            </div>
            <div>
              <img src={image2} />
            </div>
            <div>
              <img src={image3} />
            </div>
          </Carousel>
        )}

        {/* <div className="d-flex flex-center py-10 mt-lg-20">

					<img 
						alt="Logo"
						src={imageSrc}
						className="h-90px cursor-pointer" 
						onClick={() => navigate("/")}
					/>

				</div> */}

        {/* <img className="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-100px mb-10 mb-lg-0" src="/assets/media/misc/ref.png" alt="" /> */}

        {/* {
					type === layoutType.HOME ?
					<>
						<h3
							className="d-none d-lg-block text-white fs-2qx fw-bolder px-10 text-header text-center"
						>
							Earn with SolarSquare referrals
						</h3>

						<p className="d-none d-lg-block text-white mb-7 px-10 text-sub-header">
							Recommend friends and family who are interested in switching to solar. Earn ₹5,000 when they book and install a solar system with us!
						</p>

						<h3
							className="d-none d-lg-block text-white fs-2qx fw-bolder px-10 mt-5 text-header text-center"
						>
							How it works
						</h3>

						<h3
							className="d-none d-lg-block text-white fs-2qx fw-bolder mb-0 px-10 text-header"
						>
							Step 1
						</h3>
						<p className="d-none d-lg-block text-white mb-7 px-10 text-sub-header">
							Click on ‘Refer now’ to recommend your friend/ family to us
						</p>
						<h3
							className="d-none d-lg-block text-white fs-2qx fw-bolder mb-0 px-10 text-header"
						>
							Step 2
						</h3>
						<p className="d-none d-lg-block text-white mb-7 px-10 text-sub-header">
							Use the ‘Track referrals’ section to see which of your referrals transitioned to solar with us
						</p>
						<h3
							className="d-none d-lg-block text-white fs-2qx fw-bolder mb-0 px-10 text-header"
						>
							Step 3
						</h3>
						<p className="d-none d-lg-block text-white mb-7 px-10 text-sub-header">
							Earn ₹5,000 for each person who installs a rooftop solar system with us
						</p>
					</>
					:
					<h3
						className="d-none d-lg-block text-white fs-2qx fw-bolder mb-7 p-10 text-header text-center"
					>
						{text}
					</h3>
 				} */}

        {/* <div className="d-flex flex-row-fluid justify-content-center p-10">

					<div className="stepper-nav">

						<div className="stepper-item current" data-kt-stepper-element="nav">

							<div className="stepper-wrapper">

								<div className="stepper-icon rounded-3">
									<i className="stepper-check fas fa-check"></i>
									<span className="stepper-number">1</span>
								</div>


								<div className="stepper-label">
									<h3 className="stepper-title fs-2">Account Type</h3>
									<div className="stepper-desc fw-normal">Select your account type</div>
								</div>

							</div>


							<div className="stepper-line h-40px"></div>

						</div>


						<div className="stepper-item" data-kt-stepper-element="nav">

							<div className="stepper-wrapper">

								<div className="stepper-icon rounded-3">
									<i className="stepper-check fas fa-check"></i>
									<span className="stepper-number">2</span>
								</div>


								<div className="stepper-label">
									<h3 className="stepper-title fs-2">Account Info</h3>
									<div className="stepper-desc fw-normal">Setup your account settings</div>
								</div>

							</div>


							<div className="stepper-line h-40px"></div>

						</div>


						<div className="stepper-item" data-kt-stepper-element="nav">

							<div className="stepper-wrapper">

								<div className="stepper-icon">
									<i className="stepper-check fas fa-check"></i>
									<span className="stepper-number">3</span>
								</div>


								<div className="stepper-label">
									<h3 className="stepper-title fs-2">Business Details</h3>
									<div className="stepper-desc fw-normal">Setup your business details</div>
								</div>

							</div>


							<div className="stepper-line h-40px"></div>

						</div>


						<div className="stepper-item" data-kt-stepper-element="nav">

							<div className="stepper-wrapper">

								<div className="stepper-icon">
									<i className="stepper-check fas fa-check"></i>
									<span className="stepper-number">4</span>
								</div>


								<div className="stepper-label">
									<h3 className="stepper-title">Completed</h3>
									<div className="stepper-desc fw-normal">Your account is created</div>
								</div>

							</div>

						</div>

					</div>

				</div> */}

        {/* <div className="d-flex flex-center flex-wrap px-5 py-10">

					<div className="d-flex fw-normal">
						<a href="https://keenthemes.com" className="text-success px-5" target="_blank">Terms</a>
						<a href="https://devs.keenthemes.com" className="text-success px-5" target="_blank">Plans</a>
						<a href="https://1.envato.market/EA4JP" className="text-success px-5" target="_blank">Contact Us</a>
					</div>

				</div> */}
      </div>
    </div>
  );
};

export default Layout;
