import React from "react";
import Footer from "./footer";
import { useNavigate } from "react-router-dom";

const Success = ({ onPrev, onNext }) => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="w-100">
        <div className="pb-4 pb-lg-4">
          <h2 className="fw-bold text-dark">
            Yay! Your referral details have been submitted successfully.
          </h2>
        </div>

        <div className="fw-semibold mb-4">
          <div className="fs-6 text-gray-700">
            To track the status of your earnings and referrals,
            <span
              className="link-primary fw-bold cursor-pointer"
              onClick={() => navigate("/referral/status")}
            >
              {" "}
              Click here
            </span>
            .
          </div>
        </div>
      </div>

      <Footer
        prevText="Return to home"
        showNext={true}
        onPrev={onPrev}
        nextText="Refer more"
        showPrev={true}
        onNext={onNext}
      />
    </div>
  );
};

export default Success;
