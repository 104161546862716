const constants = {};

constants.REFERRAL = "referral";
constants.CHECK_STATUS = "check_status";

constants.REFERRAL_TYPE = {
    EXISTING_CUSTOMER: "existing customer",
    SSE_EMPLOYEE: "sse employee",
    SOLAR_SQUARE: "solar square",
    SOLARPRO_PARTNER: "solarpro partner",
};

constants.REFERRAL_TAB_MAPPING = {
    "1": "existing PENDING",
    "2": "IN_PROGRESS",
    "3": "COMPLETED",
    "4": "LOST",
};

constants.layoutType = {
    HOME: "HOME"
}

module.exports = constants;