import React, { useEffect, useState } from "react";
import Footer from "./footer";
import Api from "../../common/api";
import ApiUrl from "../../common/apiUrl";
import swal from "sweetalert";
import Loader from "../common/loader";

const ReferralDetail = ({ token, referralId, onPrev }) => {
  const [loading, setLoading] = useState(false);

  const [referralData, setReferralData] = useState([]);

  const getReferralDetail = async () => {
    setLoading(true);

    const data = await Api.doFetch(
      "GET",
      {},
      `${ApiUrl.REFERRAL}/overview/${referralId}`,
      token
    );

    if (data?.status) {
      setReferralData(data?.data);
    } else {
      swal("Error", data?.message || "Internal server error", "error");
    }

    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getReferralDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div
        className="card card-flush pt-3 mb-5 mb-xl-10"
        style={{ border: "1px solid #cecece" }}
      >
        <div className="card-header">
          <div className="card-title">
            <h2 className="fw-bold">Referral details</h2>
          </div>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div className="card-body pt-3">
            <div className="mb-10">
              {/* <h5 className="mb-4">Lead Details:</h5> */}

              <div className="d-flex flex-wrap py-3">
                <div className="flex-equal me-5">
                  <table className="table fs-6 fw-semibold gs-0 gy-2 gx-2 m-0">
                    <tbody>
                      <tr>
                        <td className="text-gray-400">Name</td>
                        <td className="text-gray-800">
                          <span className="text-gray-800 text-hover-primary">
                            {referralData?.name}
                          </span>
                        </td>
                      </tr>

                      <tr>
                        <td className="text-gray-400">Lead ID</td>
                        <td className="text-gray-800">
                          {referralData?.leadId || "-"}
                        </td>
                      </tr>

                      {/* <tr>
												<td className="text-gray-400">Project Location</td>
												<td className="text-gray-800">
													{referralData?.location}
												</td>
											</tr> */}

                      <tr>
                        <td className="text-gray-400">City</td>
                        <td className="text-gray-800">
                          {(referralData?.city ?? "") +
                            (referralData?.state
                              ? `, ${referralData?.state}`
                              : "")}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-gray-400">Phone</td>
                        <td className="text-gray-800">
                          {referralData?.mobile}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-gray-400">Booking status</td>
                        <td className="text-gray-800">
                          {referralData?.stage || "-"}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-gray-400">Payment status</td>
                        <td className="text-gray-800">
                          {!referralData?.paymentEligible
                            ? "None"
                            : referralData?.isPaid
                            ? "Referral Fee Paid"
                            : "Referral Fee Due"}
                        </td>
                      </tr>
                      {referralData?.isPaid && (
                        <tr>
                          <td className="text-gray-400">Transaction amount</td>
                          <td className="text-gray-800">
                            {referralData?.paidAmount}
                          </td>
                        </tr>
                      )}
                      {referralData?.isPaid && (
                        <tr>
                          <td className="text-gray-400">Transaction mode</td>
                          <td className="text-gray-800">
                            {referralData?.mode}
                          </td>
                        </tr>
                      )}
                      {referralData?.isPaid && (
                        <tr>
                          <td className="text-gray-400">Transaction ID</td>
                          <td className="text-gray-800">
                            {referralData?.utr_number}
                          </td>
                        </tr>
                      )}
                      {referralData?.isPaid && (
                        <tr>
                          <td className="text-gray-400">Transaction date</td>
                          <td className="text-gray-800">
                            {new Date(
                              referralData?.paidOn
                            ).toLocaleDateString()}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Footer showNext={false} onPrev={onPrev} />
    </div>
  );
};

export default ReferralDetail;
