import React, { useEffect, useRef } from "react";

const Location = ({ setLocation, setState, setCity, setPincode }) => {
  const inputRef = useRef(null);

  const options = {
    // bounds: new window.google.maps.LatLngBounds(
    // 	new window.google.maps.LatLng(8.0883, 76.6394), // Southwest corner of India
    // 	new window.google.maps.LatLng(28.7041, 77.1025) // Northeast corner of India
    // ),
    // strictBounds: true,
    // types: ['(regions)'],
    componentRestrictions: { country: "in" },
  };

  useEffect(() => {
    const autocomplete = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();

      // const city = place.address_components.filter(f => JSON.stringify(f.types) === JSON.stringify(['locality', 'political']))[0].short_name;
      // const state = place.address_components.filter(f => JSON.stringify(f.types) === JSON.stringify(['administrative_area_level_1', 'political']))[0].short_name;

      try {
        const displayCity = place.address_components.filter(
          (f) =>
            JSON.stringify(f.types) ===
            JSON.stringify(["locality", "political"])
        )[0];
        const displayState = place.address_components.filter(
          (f) =>
            JSON.stringify(f.types) ===
            JSON.stringify(["administrative_area_level_1", "political"])
        )[0];
        let pincode = place.address_components.filter(
          (f) => JSON.stringify(f.types) === JSON.stringify(["postal_code"])
        )[0];

        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        console.log({
          latitude,
          longitude,
          pincode,
        });

        if (pincode?.long_name) {
          setPincode(pincode?.long_name);
        }
        if (displayState?.long_name) {
          setState(displayState?.long_name);
        }
        if (displayCity?.long_name) {
          setCity(displayCity?.long_name);
        }

        setLocation(place?.formatted_address);
      } catch (error) {
        setState(undefined);
        setCity(undefined);
        setPincode(undefined);
      }

      // for (var i = 0; i < place.address_components.length; i++) {
      // 	for (var j = 0; j < place.address_components[i].types.length; j++) {
      // 		if (place.address_components[i].types[j] == "postal_code") {
      // 			console.log(place.address_components[i].long_name);
      // 		}
      // 	}
      // }
    });
    if (inputRef.current) {
      inputRef.current.addEventListener("input", (data) => {
        if (data.target.value.length <= 0) {
          setLocation(undefined);
          setState(undefined);
          setCity(undefined);
          setPincode(undefined);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <input
        name="business_descriptor"
        placeholder="Search by area or pincode"
        className="form-control form-control-lg form-control-solid"
        ref={inputRef}
      />
    </div>
  );
};

export default Location;
