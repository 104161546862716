import React, { useEffect, useRef } from "react";

const Location = ({ setLocation, setState, setCity, setPincode }) => {
  const inputRef = useRef(null);

  const options = {
    componentRestrictions: { country: "in" },
  };

  useEffect(() => {
    const autocomplete = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();

      try {
        const displayCity = place.address_components.filter(
          (f) =>
            JSON.stringify(f.types) ===
            JSON.stringify(["locality", "political"])
        )[0];
        const displayState = place.address_components.filter(
          (f) =>
            JSON.stringify(f.types) ===
            JSON.stringify(["administrative_area_level_1", "political"])
        )[0];
        let pincode = place.address_components.filter(
          (f) => JSON.stringify(f.types) === JSON.stringify(["postal_code"])
        )[0];

        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();
        console.log({
          latitude,
          longitude,
          pincode,
        });

        if (pincode?.long_name) {
          setPincode(pincode?.long_name);
        }
        if (displayState?.long_name) {
          setState(displayState?.long_name);
        }
        if (displayCity?.long_name) {
          setCity(displayCity?.long_name);
        }

        setLocation(place?.formatted_address);
      } catch (error) {
        setState(undefined);
        setCity(undefined);
        setPincode(undefined);
      }
    });
    if (inputRef.current) {
      inputRef.current.addEventListener("input", (data) => {
        if (data.target.value.length <= 0) {
          setLocation(undefined);
          setState(undefined);
          setCity(undefined);
          setPincode(undefined);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <input
        name="business_descriptor"
        placeholder="Search by area or pincode"
        className="form-control form-control-lg form-control-solid"
        ref={inputRef}
      />
    </div>
  );
};

export default Location;
